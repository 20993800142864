

import { v4 } from 'uuid'; 
import { desc } from '../config/application-submission-config';
import {filter, isEmpty, isNil, join, reduce} from 'lodash';
import { ID_CARD_RENEWAL } from '../components/DataSource';

export async function getUniqueTransNumber(appType) {

    // let commission = localStorage.getItem('route');
    // commission = commission?.toUpperCase();
    // return await getData({ url: `/transactions/transaction-number?appType=${appType}&commission_type=${commission}`});


    return v4();
    
}

export async function getBase64(file) {
    return new Promise((resolve, reject) => {

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve({contentType : reader.result.toString().slice(reader.result.indexOf(':') + 1, reader.result.indexOf(';')), base64 : reader.result.toString().slice(reader.result.indexOf('base64,') + 7), fullData: reader.result.toString()})
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject(error)
        };
    })
}

export const setFileFieldValidation = (fields = [], setValue) => {
    fields.forEach((item, index) => {
        

        if(!item || !item?.value || (item?.value?.length === 0)){
            setValue(item.key, null);
        }
    })
}

export const validateKeys = (obj, keys) => {
    const isPopulated = keys.every((key) => {
        const value = obj[key];
        if(!value) return false;

        if(Array.isArray(value) && value?.length === 0) return false;

        if(typeof value === 'object' && !Array.isArray(value)){
            const keyLen = Object.keys(value);

            if(keyLen === 0) return false;
        }

        return true;
    });

    return isPopulated;
}

export const validateSomeKeys = (obj, keys) => {
  const isPopulated = keys.some((key) => {
      const value = obj[key];
      if(!value) return false;

      if(Array.isArray(value) && value?.length === 0) return false;

      if(typeof value === 'object' && !Array.isArray(value)){
          const keyLen = Object.keys(value);

          if(keyLen === 0) return false;
      }

      return true;
  });

  return isPopulated;
}


export const isDateInRange = (startDateString, endDateString) => {
  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    ...parseMonthAndDate(startDateString)
  );
  const endDate = new Date(today.getFullYear(), ...parseMonthAndDate(endDateString));


  if (endDate < startDate) {
    // If the end date is earlier in the year than the start date,
    // it means it spans across two different years.
    // In this case, the end date should be in the next year.
    // endDate.setFullYear(today.getFullYear() + 1);
    // startDate.setFullYear(today.getFullYear() - 1);
    // return today >= startDate
  }

  debugger
  return today >= startDate || today <= endDate;
};

function parseMonthAndDate(dateString) {
  // Parse the date string 'mm-dd' and return an array [month, day]
  const [month, day] = dateString.split("-").map(Number);
  return [month - 1, day];
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const getApplicationSpecificDetails = (trans, key) => {
  if (
    trans.app_type === "APPLICATION_FOR_AQUACULTURE_FACILITY" &&
    key === "licence_type"
  ) {
    return "Commercial";
  }

  if (trans.app_type === "APPLICATION_FOR_PERMIT" && key === "licence_type") {
    key = "type_of_permit";
  } else if (
    trans.app_type === "APPLICATION_FOR_AQUACULTURE_FACILITY" &&
    key === "licence_type"
  ) {
    key = "size_of_aquaculture_facility";
  } else if (
    trans.app_type === "APPLICATION_FOR_AUTHORIZATION" &&
    key === "licence_type"
  ) {
    key = "category";
  } else if (trans.app_type === "APPLICATION_QUOTA_FISHING" && key === "licence_type") {
    key = "category";
  }


  // for previous licence number 

  if(trans.app_type === 'APPLICATION_FOR_PERMIT' && key === 'previous_licence_number') {
    key = "previous_permit_number";
  }

  let transLookup = {
    APPLICATION_FOR_LICENCE: () => trans?.["fisher_application_detail"]?.[key],
    APPLICATION_FOR_FISHER_WORKER_ID: () =>
      trans?.["fisher_worker_id_application_detail"]?.[key],
    APPLICATION_FOR_DECLARATION: () =>
      trans?.["declaration_application_detail"]?.[key],
    APPLICATION_QUOTA_FISHING: () =>
      trans?.["quota_fishing_application_detail"]?.[key],
    APPLICATION_FOR_AUTHORIZATION: () =>
      trans?.["authorization_application_detail"]?.[key],
    APPLICATION_FOR_PERMIT: () => trans?.["permit_application_detail"]?.[key],
    APPLICATION_FOR_FISHING_VESSEL: () =>
      trans?.["fishing_vessel_application_detail"]?.[key],
    APPLICATION_FOR_AQUACULTURE_FISHER: () =>
      trans?.["aquaculture_fisher_application_detail"]?.[key],
    APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: () =>
      trans?.["aqua_fishing_vessel_application_detail"]?.[key],
    APPLICATION_FOR_AQUACULTURE_FACILITY: () =>
      trans?.["aquaculture_facility_application_detail"]?.[key],
    APPLICATION_FOR_LANDING_CATCH_DECLARATION: () =>
      trans?.["landing_logs_application_detail"]?.[key],
    APPLICATION_FOR_GENERAL_ADMINISTRATION: () =>
      trans?.["general_administration_application_detail"]?.[key],
  };

  return transLookup[trans.app_type] && transLookup[trans.app_type]();
};


export const getApplicationSpecificDetailsWithMultipleKey = (trans,allData,  key) => {
  
  let transLookup = {
    APPLICATION_FOR_LICENCE: () => trans?.["fisher_application_detail"],
    APPLICATION_FOR_FISHER_WORKER_ID: () =>
      trans?.["fisher_worker_id_application_detail"],
    APPLICATION_FOR_DECLARATION: () =>
      trans?.["declaration_application_detail"],
    APPLICATION_QUOTA_FISHING: () =>
      trans?.["quota_fishing_application_detail"],
    APPLICATION_FOR_AUTHORIZATION: () =>
      trans?.["authorization_application_detail"],
    APPLICATION_FOR_PERMIT: () => trans?.["permit_application_detail"],
    APPLICATION_FOR_FISHING_VESSEL: () =>
      trans?.["fishing_vessel_application_detail"],
    APPLICATION_FOR_AQUACULTURE_FISHER: () =>
      trans?.["aquaculture_fisher_application_detail"],
    APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: () =>
      trans?.["aqua_fishing_vessel_application_detail"],
    APPLICATION_FOR_AQUACULTURE_FACILITY: () =>
      trans?.["aquaculture_facility_application_detail"],
    APPLICATION_FOR_LANDING_CATCH_DECLARATION: () =>
      trans?.["landing_logs_application_detail"],
    APPLICATION_FOR_GENERAL_ADMINISTRATION: () =>
      trans?.["general_administration_application_detail"],
  };

  const data  = transLookup[trans.app_type] && transLookup[trans.app_type]();


  return getApplicationSpecificApplicantName(data, '');


};


export const getNameOfApplicant = (data) => {
  if(data.applicant_type === 'Company'){
    return data.company_name
  } else {
    return  `${data.first_name_individual} ${data.middle_name_individual ? data.middle_name_individual + ' ' : ''}${data.last_name_individual}`
  }
}


export const getEmailAddress = (data) => {
  if(data.applicant_type === 'Company'){
    return data.company_email_address
  } else {
    return  data.email
  }
}

// Format the date and time
export const dateTimeOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true, // Use 12-hour clock format
};


export const getPreviewHeading = (appType, isFormPDF) => {
  return `${!isFormPDF ? 'Submission Transcript - ' : ''}${desc[appType]}`
}

export const getReadableDateFromMonthAndDate = (dateString) => {

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [month, day] = dateString.split('-').map(Number);

  const getOrdinalIndicator = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
      }
  };

  return `${months[month - 1]} ${day}${getOrdinalIndicator(day)}`;


}

export const concatValidStrings = (...strings) => {
  // Filter out invalid values (like null, undefined, empty strings)
  const validStrings = filter(strings, s => !isEmpty(s) && !isNil(s));

  // Join the valid strings with a space
  return join(validStrings, ' ');
}

export const fillInAllTheFields = "Please fill in all the required fields";

export const dataComplianceTerms = [
  "I understand that the National Fisheries Authority (NFA) is collecting my personal data for the purpose of promoting sustainable fisheries management and supporting the livelihoods of fishing communities in Jamaica.",
  "I understand that my data will only be used for the purpose for which it was collected and will not be used for any other purposes without my consent.",
  "I understand that the NFA will only collect and use my personal data in accordance with the DPA 2020 and the principles outlined in this statement.",
  "I have freely, specifically, informedly, and unambiguously consented to the collection and processing of my personal data for the purposes described in the statement.",
  "I understand my rights under the DPA 2020, including the right to access, rectify, erase, and restrict the processing of my personal data. I agree to exercise these rights responsibly and in accordance with the law.",
  "I acknowledge that the NFA has committed to ensuring that appropriate measures  are put in place to protect my personal data from unauthorized access, disclosure, alteration, loss, or destruction.",
  "I agree to cooperate with the NFA in its efforts to protect my personal data."
];

export const saveAndContinueLaterText = "Save & Continue Later";

export const savedToDraft = "Application saved to draft successfully";

export const passwordAndReenterShouldBeSame =  "Password and confirm password should be of same value";


export const shouldAskPreviousAppNumber = (data) => {
  let shouldAsk = false;
  if(["Amendment", "Substitute (Replacement)", "Transfer Ownership", "Replacement Licence"].indexOf(data.type_of_transaction) >= 0 ) {
    return true;
  }

  if(data.type_of_transaction === "Renewal" && data.renewal_type !== ID_CARD_RENEWAL) {
    return true
  };

  return shouldAsk;
}

export const shouldAskForNFARegNumber = (data) => {
  let shouldAsk = false;
  if(["Renewal", "Amendment", "Substitute (Replacement)", "Transfer Ownership", "Replacement Licence"].indexOf(data.type_of_transaction) >= 0 ) {
    return !data.is_nfa_reg_present;
  }

  return shouldAsk;
}


export const reportHeader = (heading, stylings = {}) => {

  return (
    <div style={{
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '24px',
      backgroundColor: 'rgb(51, 102, 153)',
      color: 'white',
      ...stylings
    }}>{heading}</div>  
  )
}

const simpleDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};
// Output: "May 20, 2024"
export const getSimpleDateOnlyFormat = (isoDate) => {
  if(!isoDate ||  !Date.parse(isoDate)) return '';
  const date = new Date(isoDate);
  return date.toLocaleDateString('en-US', simpleDateOptions);
}

export const cleanObject = (obj) => {
  // Iterate over the object's own properties
  for (const key in obj) {
    // Check if the property is an object itself (for nested objects)
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively clean nested objects
        cleanObject(obj[key]);
        
        // If the nested object is empty after cleaning, delete the key
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        // Delete the key if the value is null, undefined, or an empty string
        delete obj[key];
      }
    }
  }
}

export const getApplicationSpecificApplicantName = (data, type) => {


  return concatValidData(data?.first_name_individual , data?.middle_name_individual, data?.last_name_individual);
}

export const concatValidData = (...data) => {

  return data.reduce((a, b) => {
    a = (a || '').trim();
    b = (b || '').trim();

    return `${a} ${b}`;
  })

}

export const getRequiredTextField = (data) => {
  if(data) return "This field is required";
  else return false
}