import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getData, postData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
  FormHookTextArea,
} from "../../components/FormHook/ReactFormHookFields";
import { SpaceBar } from "@material-ui/icons";
import {
  cleanObject,
  getApplicationSpecificDetails,
  getApplicationSpecificDetailsWithMultipleKey,
  getSimpleDateOnlyFormat,
  reportHeader,
} from "../../utils/utils";
import { alertService } from "../../_services";
import { desc } from "../../config/application-submission-config";
import AlertDialogSlide from "../../components/Modal/Modal";
import ReactJson from "react-json-view";
import { cloneDeep } from "lodash";

import { transactionsUIColumns } from "./AdminDataSource";
import {
  AMENDMENT,
  COMMERCIAL,
  COMMERCIAL_FISHING,
  FISHER_LICENCE,
  FISHING_VESSEL_LICENCE,
  NEW_LICENCE,
  RECREATIONAL,
  RENEWAL,
  TRANSFER_OWNERSHIP,
  fisherCommercialLicenceCategory,
  fisherVesselLicenceCategory,
  fisherVesselLicenceTypeSubcateory,
  licenceTypeMapping,
} from "../../components/DataSource";
import Last12MonthButtons from "../../components/Last12MonthButtons";
import MultiSelect from "../../components/FormHook/MultiSelect";

const parentStyle = { margin: "10px" };
const SearchTransactions = () => {
  const linkRef = useRef();
  const [downloadHref, setDownloadHref] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");

  const manageDocumentInClient = (result) => {
    console.log(result);
    let fileContent =
      "data:" + result.data.contentType + ";base64," + result.data.base64;
    if (result.statusCode === 200) {
      setDownloadHref(fileContent);
      setDownloadFileName(result.data.downloadFileName);

      setTimeout(() => {
        linkRef.current.click();
        alertService.success("File downloaded successfully", {
          autoClose: true,
          keepAfterRouteChange: false,
        });
      }, 2000);
    } else {
      alertService.error(result.data.msg, {
        autoClose: true,
        keepAfterRouteChange: false,
      });
    }
  };

  const [allTransactions, setAllTransactions] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const [viewSingleTransaction, setViewSingleTransaction] = useState(false);

  const [currentTransaction, setCurrentTransaction] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const { errors } = formState;

  const appType = watch("app_type");

  const getStatusClassName = (status) => {
    return {
      Draft: "badge bg-warning text-dark p-2",
      "Application Received by the NFA": "badge bg-success text-light p-2",
    }[status];
  };

  const TransactionList = () => {
    return (
      <div style={parentStyle}>
        <a
          ref={linkRef}
          download
          href={downloadHref}
          download={downloadFileName}
        ></a>

        <AlertDialogSlide
          fullWidth={true}
          open={viewSingleTransaction}
          title={"Transaction Detailed View"}
          handleClose={() => {
            setViewSingleTransaction(false);
          }}
          handleCloseSecondary={() => {
            setViewSingleTransaction(false);
          }}
          style={{ width: "1300px", maxWidth: "100%", margin: "auto" }}
          content={<ReactJson src={currentTransaction}></ReactJson>}
        />

        <ResponsiveTable
          isBordered={true}
          headers={transactionsUIColumns}
          className="table-responsive table-striped"
          headerClass="blue-gradient-bg"
        >
          <tbody style={{ fontWeight: 600 }}>
            {allTransactions.map((trans, index) => {
              return (
                <tr key={trans.id}>
                  <td>{index + 1}</td>
                  <td>{getSimpleDateOnlyFormat(trans.submitted_on)}</td>
                  <td>{trans.id}</td>

                  <td>
                    {getApplicationSpecificDetailsWithMultipleKey(
                      trans,
                      {},
                      "applicant_name"
                    )}
                  </td>

                  <td> {trans?.entity?.nfa_registration_number} </td>

                  <td>
                    {getApplicationSpecificDetails(
                      trans,
                      "type_of_transaction"
                    )}
                  </td>

                  <td>
                    {getApplicationSpecificDetails(trans, "licence_type")}
                  </td>

                  <td>
                    <div className={getStatusClassName(trans.status)}>
                      {trans.status}
                    </div>
                  </td>

                  <td>
                    <button
                      className="d-flex justify-content-center flex-column btn btn-warning"
                      onClick={async (e) => {
                        let clonedCopy = cloneDeep(trans);
                        cleanObject(clonedCopy);
                        setCurrentTransaction(clonedCopy);
                        setViewSingleTransaction(true);
                      }}
                    >
                      <span className="material-icons-outlined">
                        visibility
                      </span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </div>
    );
  };

  const submit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    const data = getValues();

    const result = await getData({
      url: `${ROUTES.searchTransactionsForAdmin}`,
      query: data,
    });

    if (result.statusCode === 200) {
      alertService.success(result.data.msg);
      setAllTransactions(result.data.transactions);
    }
  };

  return (
    <div className="simple-border-2">
      <div className="simple-border" style={{ margin: "10px" }}>
        {reportHeader("Transactions Report")}

        <div style={parentStyle}>
          {/* Search Fields */}
          <div className="d-flex-wrap flex-row justify-content-between">
            <FormHookSelect
              parentClass=" individual-field max-250 flex-full-height"
              fieldClassName="form-control "
              label="Select Application Type"
              register={register}
              regFieldName={`app_type`}
              options={[
                {
                  value: "APPLICATION_FOR_LICENCE",
                  label: "Application for Fisher Licence",
                },
                {
                  value: "APPLICATION_FOR_FISHING_VESSEL",
                  label: "Application For Fishing Vessel Licence",
                },
                {
                  value: "APPLICATION_FOR_DECLARATION",
                  label: "Application for Declarations",
                },
                {
                  value: "APPLICATION_QUOTA_FISHING",
                  label: "Application for Quota and Fishing Rights",
                },
                {
                  value: "APPLICATION_FOR_AUTHORIZATION",
                  label: "Application For Authorization",
                },
                {
                  value: "APPLICATION_FOR_PERMIT",
                  label: "Application For Permit",
                },

                {
                  value: "APPLICATION_FOR_AQUACULTURE_FISHER",
                  label: "Application for Aquaculture Fish Farmer Licence",
                },
                {
                  value: "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL",
                  label: "Application for Aquaculture Fishing Vessel",
                },
                {
                  value: "APPLICATION_FOR_AQUACULTURE_FACILITY",
                  label: "Application for Aquaculture Facility",
                },
                {
                  value: "APPLICATION_FOR_LANDING_CATCH_DECLARATION",
                  label: "Landing Catch Declaration",
                },
                {
                  value: "APPLICATION_FOR_GENERAL_ADMINISTRATION",
                  label: "Application for Letters & Other Services",
                },
                {
                  value: "APPLICATION_FOR_FISHER_WORKER_ID",
                  label: "Application for Fisher Worker Id",
                },
              ]}
              isRequired={"This field is required"}
              isKeyValue={true}
            />

            <FormHookInput
              register={register}
              error={errors?.start_date?.message}
              label="Start Date"
              regFieldName="start_date"
              type="date"
              isRequired={false}
            />

            <FormHookInput
              parentClass="individual-field flex-remaining-trans"
              register={register}
              error={errors?.end_date?.message}
              label="End Date"
              regFieldName="end_date"
              type="date"
              isRequired={false}
            />

            <MultiSelect
              register={register}
              handleSubmit={handleSubmit}
              formState={{ errors }}
              setValue={setValue}
              name="status"
              label="Transaction Status"
              options={[
                {
                  value: "Application Received by NFA",
                  label: "Application Received by NFA",
                },
                {
                  value: "Application In Progress",
                  label: "Application In Progress",
                },
                { value: "Case Closed", label: "Case Closed" },
                // { value: 'Draft', label: 'Draft'},
                { value: "In Progress", label: "In Progress" },
                { value: "Licence Approved", label: "Licence Approved" },
                { value: "Payment Approved", label: "Payment Approved" },
                {
                  value: "Returned For Correction",
                  label: "Returned For Correction",
                },
              ]}
            />

            <FormHookSelect
              parentClass=" individual-field max-250 flex-full-height"
              fieldClassName="form-control "
              label="Select Payment Method"
              register={register}
              regFieldName={`payment_method`}
              options={[
                { value: "Cash at NFA Office", label: "Cash at NFA Office" },
                { value: "Cheque", label: "Cheque" },
                { value: "Credit/Debit Card", label: "Credit/Debit Card" },
                { value: "Wire/Bank Transfer", label: "Wire/Bank Transfer" },
              ]}
              isRequired={false}
              isKeyValue={true}
            />

            {/* <MultiSelect
              register={register}
              handleSubmit={handleSubmit}
              formState={{ errors }}
              setValue={setValue}
              name="payment_method"
              label="Payment Method"
              options={[
                { value: 'Cash at NFA Office', label: 'Cash at NFA Office'},
                { value: 'Cheque', label: 'Cheque'}, 
                { value: 'Credit/Debit Card', label: 'Credit/Debit Card'},
                { value: 'Wire/Bank Transfer', label: 'Wire/Bank Transfer'},
              ]}
            /> */}

            {[
              "APPLICATION_FOR_AQUACULTURE_FACILITY",
              "APPLICATION_QUOTA_FISHING",
              "APPLICATION_FOR_LANDING_CATCH_DECLARATION",
            ].indexOf(appType) < 0 && (
              <FormHookSelect
                parentClass=" individual-field max-250 "
                fieldClassName="form-control "
                label="Type of Transaction"
                register={register}
                regFieldName={`type_of_transaction`}
                options={[
                  {
                    value: NEW_LICENCE,
                    label: NEW_LICENCE,
                  },
                  {
                    value: AMENDMENT,
                    label: AMENDMENT,
                  },
                  {
                    value: RENEWAL,
                    label: RENEWAL,
                  },
                  {
                    value: TRANSFER_OWNERSHIP,
                    label: TRANSFER_OWNERSHIP,
                  },
                ]}
                isRequired={false}
                isKeyValue={true}
              />
            )}

            {[
              "APPLICATION_FOR_AQUACULTURE_FACILITY",
              "APPLICATION_QUOTA_FISHING",
              "APPLICATION_FOR_LANDING_CATCH_DECLARATION",
            ].indexOf(appType) >= 0 && (
              <FormHookSelect
                parentClass=" individual-field max-250 "
                fieldClassName="form-control "
                label="Type of Transaction"
                register={register}
                regFieldName={`type_of_transaction`}
                options={[
                  {
                    value: "New",
                    label: NEW_LICENCE,
                  },
                  {
                    value: AMENDMENT,
                    label: AMENDMENT,
                  },
                  {
                    value: RENEWAL,
                    label: RENEWAL,
                  },
                ]}
                isRequired={false}
                isKeyValue={true}
              />
            )}

            {appType === "APPLICATION_FOR_LICENCE" && (
              <>
                <FormHookSelect
                  label="Licence Type"
                  options={licenceTypeMapping[FISHER_LICENCE] ?? []}
                  register={register}
                  error={errors?.licence_type?.message}
                  regFieldName={`licence_type`}
                  isRequired={false}
                />

                {watch("licence_type") === COMMERCIAL_FISHING && (
                  <FormHookSelect
                    label="Category"
                    options={fisherCommercialLicenceCategory ?? []}
                    register={register}
                    error={errors?.commercial_category?.message}
                    regFieldName={`commercial_category`}
                    isRequired={false}
                  />
                )}
              </>
            )}

            {appType === "APPLICATION_FOR_FISHING_VESSEL" && (
              <>
                <FormHookSelect
                  label="Licence Type"
                  options={
                    fisherVesselLicenceCategory[FISHING_VESSEL_LICENCE] ?? []
                  }
                  register={register}
                  error={errors?.licence_type?.message}
                  regFieldName={`licence_type`}
                  isRequired={false}
                />

                {watch("licence_type") === COMMERCIAL && (
                  <FormHookSelect
                    label="Category"
                    options={
                      fisherVesselLicenceTypeSubcateory[
                        watch("licence_type")
                      ] ?? []
                    }
                    register={register}
                    error={errors?.commercial_category?.message}
                    regFieldName={`commercial_category`}
                    isRequired={false}
                  />
                )}

                {watch("licence_type") === RECREATIONAL && (
                  <FormHookSelect
                    label="Category"
                    options={
                      fisherVesselLicenceTypeSubcateory[
                        watch("licence_type")
                      ] ?? []
                    }
                    register={register}
                    error={errors?.recreational_licence_type?.message}
                    regFieldName={`recreational_licence_type`}
                    isRequired={false}
                  />
                )}
              </>
            )}
          </div>

          {/* CTAS */}
          <div className="m-2 d-flex-wrap flex-row justify-content-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={async () => {
                submit();
              }}
            >
              Submit
            </button>

            <button
              className="btn ml-2 btn-warning"
              type="button"
              onClick={async () => {
                const isValid = await trigger();
                if (isValid) {
                  const data = getValues();

                  const result = await postData({
                    url: `${ROUTES.exportExcelReport}/search_transactions`,
                    body: data,
                  });
                  manageDocumentInClient(result);
                }
              }}
            >
              Download Report
            </button>
          </div>

          {/* Last 12 Month Buttons */}
          <Last12MonthButtons
            heading="Monthly Data"
            className="m-2"
            onClick={(monthIndex) => {
              const year =
                new Date().getFullYear() - Math.floor(monthIndex / 12);
              const adjustedMonth = ((monthIndex % 12) + 12) % 12; // Adjust for negative months

              const startDateOriginal = new Date(year, adjustedMonth, 1);
              const endDateOriginal = new Date(year, adjustedMonth + 1, 0);

              const startDate = new Date(
                startDateOriginal.getTime() -
                  startDateOriginal.getTimezoneOffset() * 60000
              )
                .toISOString()
                .substring(0, 10);

              const endDate = new Date(
                endDateOriginal.getTime() -
                  endDateOriginal.getTimezoneOffset() * 60000
              )
                .toISOString()
                .substring(0, 10);

              setValue("start_date", startDate);
              setValue("end_date", endDate);
              submit();
            }}
          />
        </div>
      </div>
      <TransactionList></TransactionList>
    </div>
  );
};

export default SearchTransactions;
