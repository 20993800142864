import React, { useContext, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { alertService } from "../../_services";
import queryString from "query-string";
import HorizontalLine from "../HorLine";
import background from "../../../assets/images/fisheries.jpeg";
import Profile360 from "../../containers/Profile360";
import AlertDialogSlide from "../Modal/Modal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FLISContext } from "../../context/FLISContext";
import DocumentViewer from "../DocumentViewer";
import { FormHookFileUploadHandler } from "../FormHook/FormHookDocument";
import { useFieldArray, useForm } from "react-hook-form";
import { getData, postData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { signInIcon } from "../../../assets/icons/icons_svg";
import { ResponsiveTable } from "../ResponsiveTable";

import store from "../../redux/store";

import { getNameOfApplicant } from "../../utils/utils";
import { uniqueId } from "lodash";
export const ResponsiveNavbar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const { currentEntity, setCurrentEntity, associatedEntities = [] } = useContext(FLISContext);

  const [dialog, showDialog] = useState(false);

  const [multipleEntityDialog, showMultipleEntityDialog] = useState(false);


  isLoggedIn = localStorage.getItem("logindata") ?? "{}";
  const [login, setLogin] = useState(JSON.parse(isLoggedIn));

  isLoggedIn = localStorage.getItem("isloogedIn") === "true" ?? false;
  // let queryParams = useParams();
  let queryParams = queryString.parse(props.location?.search);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const {errors} = formState;

  // Define an array of routes where you want to hide the navbar
  const routesWithoutSecondNavBar = ["/register", "/form-render-for-pdf", 
    // "/admin", 
    // "/admin/transactions", 
    // "/admin/species-seasons", 
    // "/admin/licences"
  ];

  const routesWithoutNavbarOptions = [
    "/admin",
    "/admin/transactions",
    "/admin/species-seasons",
    "/admin/licences",
    "/admin/supporting-officer",
  ];
  
  const routesWithoutLogo = ["/register"];
  const routersWithFirstNavBar = ["/register", "/home"];
  const routesWithoutLoginLink = ['/login'];

  const homeButtonStyle = {
    backgroundColor: "green",
    color: "white",
    width: "200px",
    fontWeight: 600,
    fontSize: "18px",
    padding: "0px",
  };

  const dashboardButtonStyle = {
    backgroundColor: "green",
    color: "white",
    // width: "200px",
    fontWeight: 600,
    fontSize: "18px",
    padding: "6px",
  };

  const onlyWidth = {
    width: '150px'
  }

  const nameButtonStyle = {
    backgroundColor: '#336699',
    maxWidth: '400px',
    width: '100%'
  }

  const applicantProfileButtonStyle = {
    backgroundColor: 'yellow',
    color: 'black',
  }

  const logoutButtonStyle = {
    backgroundColor: 'red',
    width: '100%'
  }

  const redButtonStyle = {
    backgroundColor: 'red',
  }

  const loginButtonStyle = {
    backgroundColor: '#336699',
    width: '100px'
  }

  const profilePhoto = watch("self_photo_file");

  useEffect(() => {

    if(profilePhoto){
      const data = getValues();
      putData({
        url: `${ROUTES.updateEntityInformation}/${currentEntity.id}`,
        body: data,
      }).then(result => {

        if (result.success) {
          alertService.success(result.data.msg);
          setCurrentEntity(result.data.entity);
        } else {
          alertService.warn(result.data.msg);
        }

      });

      

      
    }
  }, [profilePhoto]);



  const SelectEntityToLogin = ({ entities = [] }) => {
    return (
      <div>
        <ResponsiveTable
          tableClass="table-striped"
          headerClass="thead-dark"
          headers={[
            "S. No.",
            "NFA Reg. #",
            "TRN",
            "Applicant Type",
            "Applicant Name",
            "Email",
            "Telephone Number",
            "Entity Number",
            "Date of Reg./Birth",
            "Login",
          ]}
        >
          <tbody>
            {entities.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.nfa_registration_number}</td>
                  <td>{item.taxation_registration_number}</td>
                  <td>{item.applicant_type}</td>
                  <td>{getNameOfApplicant(item)}</td>
                  <td>{item.email}</td>
                  <td>{item.cell_phone_user}</td>
                  <td>{item.entity_number}</td>
                  <td>
                    {item.applicant_type === "Individual"
                      ? item?.date_of_birth_individual?.substring(0, 10)
                      : item.date_of_registration}
                  </td>

                  <td>
                    <button
                      className="btn btn-success"
                      onClick={async () => {
                        const values = {
                          entity_id: item.id,
                          user_id: currentEntity.entity_user.user_id,
                        };

                        
                        const result = await postData({
                          url: `${ROUTES.switchApplicant}/${currentEntity.entity_user.entity_id}/${currentEntity.entity_user.user_id}`,
                          body: values,
                        });

                        if (result.statusCode === 200) {
                          alertService.success("Switched Applicant Successfully", {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                          localStorage.setItem("token", result.data.token);
                          store.dispatch({
                            type: "LOGIN",
                            token: result.data.token,
                          });
                          history.push("/application-types");

                          window.location.reload();
                        } else if (result.statusCode === 401) {
                          alertService.error(result.data.msg, {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                        }
                      }}
                    >
                      Login
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </div>
    );
  };

  return (
    <>
      <AlertDialogSlide
        fullWidth={true}
        open={multipleEntityDialog}
        title={""}
        handleClose={() => {
          showMultipleEntityDialog(false);
        }}
        handleCloseSecondary={() => {
          showMultipleEntityDialog(false);
        }}
        primaryActionName={"Close"}
        content={<SelectEntityToLogin entities={associatedEntities} />}
      ></AlertDialogSlide>

      <AlertDialogSlide
        fullWidth={true}
        open={dialog}
        title={""}
        handleClose={() => {
          showDialog(false);
        }}
        primaryActionName={"Close"}
        content={<Profile360></Profile360>}
      ></AlertDialogSlide>
      {
        !navigator.onLine && <span className="offline-top-message">You are offline</span>
      }

      {routersWithFirstNavBar.includes(location.pathname) && (
        <nav
          className="navbar navbar-expand-lg navbar-light bg-primary"
          style={{
            background: "#00b7fd !important",
            color: "white",
          }}
        >
          <a
            href="#"
            className="navbar-brand d-flex justify-content-start align-items-center"
          >
            <div>
              <img
                alt="Fisheries"
                className="flis-image"
                src="./images/flis_logo.png"
              ></img>
            </div>

            <div
              className="text-s-bold"
              style={{
                fontSize: "1.75rem",
                paddingLeft: "10px",
              }}
            >
              <div>National Fisheries Authority</div>
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      )}

      {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <a className="nav-link" href="/login">
                  Login <span className="sr-only">(Log In)</span>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/register">
                  Create Account
                </a>
              </li>
            </ul>
          </div> */}

      {!routesWithoutSecondNavBar.includes(location.pathname) && (
        <div
          style={{ padding: "10px 0% 10px 0%", cursor: "pointer" }}
          className="ml-4 mr-4 d-flex-wrap  justify-content-between"
        >
          {
            // currentEntity?.self_photo_file &&
            ["/profile"].includes(location.pathname) && (
              <div>
                <DocumentViewer
                  style={{ paddingLeft: "25px" }}
                  toPreview={true}
                  id={currentEntity?.self_photo_file}
                  type="image"
                ></DocumentViewer>

                <FormHookFileUploadHandler
                  error={errors?.self_photo_file?.message}
                  register={register}
                  watch={watch}
                  label="Entity Profile Photo"
                  accept="image/png, image/jpeg, image/jpg"
                  regFieldName="self_photo_file"
                  fieldClassName="field-block-control form-control  "
                  parentClass="individual-field "
                  isPreviewable={false}
                  removeFile={(id) => setValue(id, null)}
                  setValue={setValue}
                  isRequired={false}
                  toShowInstructionLink={true}
                />
              </div>
            )
          }
          <div className="d-flex flex-column justify-content-between align-items-center">
            <img
              className="flis-second-image"
              onClick={() => history.push(`/application-types`)}
              alt="Fisheries"
              src="./images/Irie FINS Logo NoText-01.png"
            ></img>

            <button
              className="btn btn-sm"
              style={homeButtonStyle}
              onClick={() => history.push("/application-types")}
            >
              Home
            </button>
          </div>

         {
          !routesWithoutNavbarOptions.includes(location.pathname) &&  <div
          style={{
            paddingLeft: "10px",
          }}
          className="d-flex-wrap flex-column justify-content-between align-items-end flex-spacing-10"
        >
          <div className="d-flex-wrap flex-row mt-4 justify-content-between align-items-center">
            {currentEntity && (
              <>
                <span>
                  <a
                    style={{
                      fontWeight: 700,
                      color: "black",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    href="/profile"
                  >
                    <button
                      className="btn btn-sm"
                      style={{ ...dashboardButtonStyle }}
                    >
                      {currentEntity.applicant_type === "Company" &&
                        currentEntity.company_name}

                      {currentEntity.applicant_type === "Individual" &&
                        `${currentEntity.first_name_individual} ${currentEntity.middle_name_individual} ${currentEntity.last_name_individual}`}


                        { ` - Dashboard`}
                    </button>
                  </a>
                </span>

                <div className="d-flex-wrap flex-row justify-content-center align-items-center">
                  <a
                    style={{
                      fontWeight: 700,
                      color: "red",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    href="/logout"
                  >
                    <button
                      onClick={() => {
                        localStorage.setItem("token", "");
                        history.push("/logout");
                      }}
                      className="btn btn-sm"
                      style={{ ...dashboardButtonStyle, ...logoutButtonStyle }}
                    >
                      Logout
                    </button>
                  </a>
                </div>
              </>
            )}

            {!currentEntity &&
              !routesWithoutLoginLink.includes(location.pathname) && (
                <div className="d-flex flex-row">
                  <button
                    onClick={() => {
                      localStorage.setItem("token", "");
                      history.push("/login");
                    }}
                    className="btn btn-sm"
                    style={{ ...homeButtonStyle, ...loginButtonStyle }}
                  >
                    Login
                  </button>

                  <button
                    onClick={() => {
                      localStorage.setItem("token", "");
                      history.push("/register");
                    }}
                    className="btn btn-sm"
                    style={{ ...homeButtonStyle, ...loginButtonStyle }}
                  >
                    Register
                  </button>
                </div>
              )}
          </div>

          <div>
            {/* {currentEntity && (
              <button
                onClick={() => {
                  history.push("/profile");
                }}
                title="Click here to access your profile/transaction details"
                className="btn btn-sm"
                style={{ ...homeButtonStyle }}
              >
                Applicant Profile
              </button>
            )} */}

             {currentEntity && (
              <button
                onClick={() => {
                  history.push(`/transaction-status?id=${uniqueId()}`);
                }}
                title="Click here to check your application status"
                className="btn btn-sm"
                style={{ ...homeButtonStyle, backgroundColor: 'yellow', color: 'black', width: '300px', border: '1px solid black' }}
              >
                Check Your Application Status
              </button>
            )}

            {currentEntity && associatedEntities?.length > 1 && (
              <button
                onClick={async () => {
                  showMultipleEntityDialog(true);
                }}
                className="btn btn-sm"
                style={{ ...homeButtonStyle, ...onlyWidth }}
              >
                Switch Applicant
              </button>
            )}


            {
              !currentEntity && <button
              className="btn btn-sm"
              style={{
                ...homeButtonStyle,
                ...redButtonStyle,
                marginRight: "20px",
              }}
              onClick={async () => {
                history.push('/reset-password');
              }}
              type="button"
            >
              Reset Password
            </button>
            }
            <button
              className="btn btn-sm"
              style={{
                ...homeButtonStyle,
                marginRight: "20px",
              }}
              onClick={async () => {
                window.open("https://iriefins.tawk.help/", "_blank");
              }}
              type="button"
            >
              Help Center
            </button>
          </div>
        </div>
         }
        </div>
      )}

      {routesWithoutLogo.includes(location.pathname) && (
        <div
          style={{ cursor: "pointer" }}
          className="d-flex flex-column align-items-start justify-content-between "
        >
          <img
            className="flis-second-image"
            onClick={() => history.push(`/application-types`)}
            alt="Fisheries"
            src="./images/Irie FINS Logo NoText-01.png"
          ></img>

          <button
            className="btn btn-sm"
            style={homeButtonStyle}
            onClick={() => history.push("/application-types")}
          >
            Home
          </button>

          <HorizontalLine></HorizontalLine>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () =>
    dispatch({
      type: "LOGOUT",
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveNavbar);
