import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getData, postData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import {
  getNameOfApplicant,
    getSimpleDateOnlyFormat,
  reportHeader,
} from "../../utils/utils";
import { alertService } from "../../_services";
import { desc } from "../../config/application-submission-config";
import AlertDialogSlide from "../../components/Modal/Modal";
import ReactJson from "react-json-view";
import { cloneDeep } from "lodash";

import {
  searchEntitiesUIColumns,
} from "./AdminDataSource";


const parentStyle = { margin: "10px" };
const SupportingOfficerDashboard = () => {
  const linkRef = useRef();
  const [downloadHref, setDownloadHref] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");

  const [allEntities, setAllEntities] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const [viewSingleTransaction, setViewSingleTransaction] = useState(false);

  const [currentEntity, setCurrentEntity] = useState({});

  const [applicantUsers, setApplicantUsers] = useState([]);
  const [supportingOfficerUsers, setSupportingOfficerUsers] = useState([]);

  const [supportingOfficers, setSupportingOfficers] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const { errors } = formState;

  const TransactionList = () => {
    return (
      <div style={parentStyle}>
        <a ref={linkRef} href={downloadHref} download={downloadFileName}></a>

        <AlertDialogSlide
          fullWidth={true}
          open={viewSingleTransaction}
          handleClose={() => {
            setViewSingleTransaction(false);
          }}
          handleCloseSecondary={() => {
            setViewSingleTransaction(false);
          }}
          style={{ width: "1300px", maxWidth: "100%", margin: "auto" }}
          content={
            <div>
              <h3>Applicant Users</h3>
              <ResponsiveTable
                isBordered={true}
                headers={["ID", "Name", "Email", "Cell Phone"]}
                className="table-responsive table-striped"
                headerClass="blue-gradient-bg"
              >
                <tbody style={{ fontWeight: 600 }}>
                  {applicantUsers.map((user, index) => (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      <td>{user.name_of_applicant}</td>
                      <td>{user.user_name}</td>
                      <td>{user.cell_phone_user}</td>
                    </tr>
                  ))}
                </tbody>
              </ResponsiveTable>

              <h3>Supporting Officer Users</h3>
              <ResponsiveTable
                isBordered={true}
                headers={["ID", "Name", "Email", "Cell Phone", "Claim"]}
                className="table-responsive table-striped"
                headerClass="blue-gradient-bg"
              >
                <tbody style={{ fontWeight: 600 }}>
                  {supportingOfficerUsers.map((user, index) => (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      <td>{user.supporting_officer_name}</td>
                      <td>{user.email}</td>
                      <td>{user.cell_phone_user}</td>
                      <td>
                        <button
                          className="d-flex justify-content-center flex-column btn btn-warning"
                          onClick={async () => {
                            // claim the entity and add the current user as the supporting officer
                            // do the api call to change the supporting officer

                            const result = await putData({
                              url: `${ROUTES.replaceSupportingOfficerInEntity}/${currentEntity.id}/${user.id}`,
                            });

                            if (result.statusCode === 200) {
                              alertService.success(result.data.msg);
                              setViewSingleTransaction(false);
                            } else {
                              alertService.warn(result.data.msg);
                            }
                          }}
                        >
                          Claim
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ResponsiveTable>
            </div>
          }
        />

        <ResponsiveTable
          isBordered={true}
          headers={searchEntitiesUIColumns}
          className="table-responsive table-striped"
          headerClass="blue-gradient-bg"
        >
          <tbody style={{ fontWeight: 600 }}>
            {allEntities?.map((entity, index) => {
              return (
                <tr key={entity.id}>
                  <td>{index + 1}</td>
                  <td>{entity.nfa_registration_number}</td>
                  {/* <td>{entity?.users?.supporting_officer_name}</td> */}
                  <td>{getNameOfApplicant(entity)}</td>
                  <td>{entity?.applicant_email_address || entity?.applicant_cell_phone}</td>
                  <td>
                    {entity?.users?.entity_user
                      ?.is_replaced_supporting_officer === "YES"
                      ? getSimpleDateOnlyFormat(
                          entity?.users?.entity_user.updated_at
                        )
                      : null}
                  </td>

                  <td>
                    <button
                      className="d-flex justify-content-center flex-column btn btn-warning"
                      onClick={async () => {
                        setCurrentEntity(entity);

                        const result = await putData({
                          url: `${ROUTES.replaceSupportingOfficerInEntity}/${entity.id}/${entity?.users?.id ? entity?.users?.id : '0'}`,
                        });

                        if (result.statusCode === 200) {
                          alertService.success(result.data.msg);
                        submit();
                        } else {
                          alertService.warn(result.data.msg);
                        }
                      }}
                    >
                      Claim
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </div>
    );
  };

  const submit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    const data = getValues();

    const result = await getData({
      url: `${ROUTES.searchEntities}`,
      query: data,
    });

    if (result.statusCode === 200) {
      alertService.success(result.data.msg);
      setAllEntities(result.data.entities);
    } else {
      alertService.warn(result.data.msg);
      setAllEntities([]);
    }
  };

  useEffect(() => {

    // get all supporting officers

    const fetchSupportingOfficers = async () => {
        const result = await getData({
            url: `${ROUTES.getALLSupportingOfficers}`,
            headers: {
                token: process.env.REACT_APP_API_TOKEN,
            }
        });
    
        if (result.statusCode === 200) {
            const users = result.data.users;

            
            setSupportingOfficers(users.map((user) => {
                return {
                    value: user.id,
                    label: user.email,
                }
            }));

        } else {
            alertService.error(result.data.msg);
        }
    }

    fetchSupportingOfficers();
        
  }, []);

  return (
    <div className="simple-border-2">
      <div className="simple-border" style={{ margin: "10px" }}>
        {reportHeader("Supporting Officer Dashboard")}

        <div style={parentStyle}>
          {/* Search Fields */}
          <div className="d-flex-wrap flex-row justify-content-start">
            <FormHookInput
              register={register}
              error={errors?.nfa_registration_number?.message}
              label="NFA Reg #"
              regFieldName="nfa_registration_number"
              isRequired={false}
            />

            <FormHookSelect
              parentClass=" individual-field  max-500 flex-full-height"
              fieldClassName="form-control "
              label="Select Supporting Officer (Optional)"
              register={register}
              regFieldName={`supporting_officer_id`}
              options={supportingOfficers}
              isRequired={false}
              isKeyValue={true}
            />
          </div>

          {/* CTAS */}
          <div className="m-2 d-flex-wrap flex-row justify-content-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={async () => {
                submit();
              }}
            >
              Submit
            </button>

          </div>
        </div>
      </div>
      <TransactionList></TransactionList>
    </div>
  );
};

export default SupportingOfficerDashboard;
