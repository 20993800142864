import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  apiHeaders,
  getData,
  postData,
  putData,
} from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
  FormHookTextArea,
} from "../../components/FormHook/ReactFormHookFields";
import { SpaceBar } from "@material-ui/icons";
import {
  cleanObject,
  getSimpleDateOnlyFormat,
  reportHeader,
} from "../../utils/utils";
import { alertService } from "../../_services";
import { desc } from "../../config/application-submission-config";
import AlertDialogSlide from "../../components/Modal/Modal";
import ReactJson from "react-json-view";
import { getCaseRow } from "../../components/DataSource";

import DocumentPreviewWithBlob from "../../components/DocumentPreviewWithBlob";
import { searchLicenceUIColumns } from "./AdminDataSource";

const parentStyle = { margin: "10px" };
const SearchLicences = () => {

    const linkRef = useRef();
    const [downloadHref, setDownloadHref] = useState('');
    const [downloadFileName, setDownloadFileName] = useState('');
  
    const manageDocumentInClient = (result) => {
      console.log(result);
      let fileContent = "data:" + result.data.contentType + ';base64,' + result.data.base64;
      if (result.statusCode === 200) {
        setDownloadHref(fileContent);
        setDownloadFileName(result.data.downloadFileName);
  
        setTimeout(() => {
          linkRef.current.click();
          alertService.success('File downloaded successfully', { autoClose: true, keepAfterRouteChange: false });
        }, 2000);
  
      } else {
        alertService.error(result.data.msg, { autoClose: true, keepAfterRouteChange: false });
      }
    }

    

  const [licences, setAllLicences] = useState([]);

  const [dialog, showDialog] = useState(false);

  const [preview, showPreview] = useState(false);

  const [blob, setBlob] = useState(null);

  const [viewSingleTransaction, setViewSingleTransaction] = useState(false);

  const [currentTransaction, setCurrentTransaction] = useState({});

  const SeachForm = ({ defaultValue = {} }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState,
      setValue,
      getValues,
      trigger,
    } = useForm({
      defaultValues: defaultValue,
      mode: "all",
    });

    const { errors } = formState;

    return (
      <div style={parentStyle}>
        <div className="d-flex-wrap flex-row justify-content-start"></div>

        <div className="m-2 d-flex-wrap flex-row justify-content-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) {
                const data = getValues();

                let result = await postData({
                  url: `${ROUTES.searchLicencesForAdmin}`,
                  body: data,
                });

                if (Array.isArray(result)) {
                  setAllLicences(result);
                } else {
                  alertService.warn(
                    "Error while getting issued certificates, please try again."
                  );
                }
              }
            }}
          >
            Submit
          </button>

          <button
            className="btn ml-2 btn-warning"
            type="button"
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) {
                const data = getValues();
                const result = await postData({
                  url: `${ROUTES.exportExcelReport}/search_licences`,
                  body: data,
                });

                manageDocumentInClient(result);
              }
            }}
          >
            Download Report
          </button>
        </div>
      </div>
    );
  };

  const downloadCertificate = async (trans, type) => {
    // Assuming you're using axios or fetch to make the API call
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${ROUTES.downloadLicenceCertificate}/${trans.propertiesMap.FLIS_TransactionId}`,
        {
          certificate_type: type,
          case_type: trans.caseType
        },
        {
          headers: apiHeaders(),
          responseType: "blob", // Set the response type to blob
        }
      )
      .then((response) => {
        // Create a blob from the response data
        const mimeType = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: mimeType,
        });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "document.pdf";
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  const previewCertificate = async (trans, type) => {
    // Assuming you're using axios or fetch to make the API call
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${ROUTES.downloadLicenceCertificate}/${trans.propertiesMap.FLIS_TransactionId}`,
        {
          certificate_type: type,
          case_type: trans.caseType
        },
        {
          headers: apiHeaders(),
          responseType: "blob", // Set the response type to blob
        }
      )
      .then((response) => {
        // Create a blob from the response data
        const mimeType = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: mimeType,
        });

        setBlob(blob);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  const TransactionList = () => {
    return (
      <div style={parentStyle}>
        <AlertDialogSlide
          fullWidth={false}
          open={dialog}
          title={"Documents"}
          handleClose={async () => {}}
          handleCloseSecondary={() => {
            showDialog(false);
          }}
          secondaryActionName={"Close"}
          content={
            <div
              style={{
                minWidth: "400px",
              }}
            >
              <div className="d-flex-wrap flex-row  justify-content-between">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      previewCertificate(currentTransaction, "licence");
                      showDialog(false);
                      showPreview(true);
                    }}
                    className="btn btn-primary btn-block w-100"
                  >
                    Preview Licence
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      downloadCertificate(currentTransaction, "licence");
                    }}
                    className="btn btn-secondary btn-block w-100"
                  >
                    Download Licence
                  </button>
                </div>
              </div>


              {
                ["FLIS_ApplicationforLicence"].indexOf(currentTransaction?.caseType) >= 0 && 
                ( <div className="d-flex-wrap flex-row  justify-content-between">
                  <div>
                    <button
                      onClick={() => {
                        previewCertificate(currentTransaction, "card");
                        showDialog(false);
                        showPreview(true);
                      }}
                      type="button"
                      className="btn btn-primary btn-block w-100"
                    >
                      Preview ID Card
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        downloadCertificate(currentTransaction, "card");
                      }}
                      type="button"
                      className="btn btn-secondary btn-block w-100"
                    >
                      Download ID Card
                    </button>
                  </div>
                </div>
                )
              }
            </div>
          }
        />

        <AlertDialogSlide
          fullWidth={false}
          open={preview}
          title={"Preview"}
          handleClose={async () => {}}
          handleCloseSecondary={() => {
            showPreview(false);
          }}
          secondaryActionName={"Close"}
          content={
            <div
              style={{
                minWidth: "800px",
                maxWidth: "100%",
                maxHeight: "80vh",
              }}
            >
              {blob ? (
                <DocumentPreviewWithBlob blob={blob} />
              ) : (
                <div>Loading document...</div>
              )}
            </div>
          }
        />

        <AlertDialogSlide
          fullWidth={true}
          open={viewSingleTransaction}
          title={"Transaction Detailed View"}
          handleClose={() => {
            setViewSingleTransaction(false);
          }}
          handleCloseSecondary={() => {
            setViewSingleTransaction(false);
          }}
          style={{ width: "1300px", maxWidth: "100%", margin: "auto" }}
          content={<ReactJson src={currentTransaction}></ReactJson>}
        />

        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <thead className="custom-table-header">
              <tr>
                {
                    searchLicenceUIColumns.map((header) => {
                        return <th>{header}</th>;
                    })
                }
                {/* <th>Type of Licence/Permit</th>
                <th>Permit/Licence #</th>
                <th>Category</th>
                <th>Type of Fish/Species</th>
                <th>Port / Inland</th>
                <th>Date of Issue</th>
                <th>Date of Expiration</th>
                <th>Card Issue Date</th>
                <th>Card Expiry Date</th>
                <th>Licence/Card Certificate</th> */}
              </tr>
            </thead>

            <tbody className="table-striped">
              {licences?.map((trans) => {
                const caseRow = getCaseRow(trans);
                if (!caseRow) return null;
                return (
                  <tr>

                    <td></td>

                    {caseRow}

                  
                    <td>
                      <button
                        className="d-flex justify-content-center flex-column btn btn-warning"
                        onClick={async (e) => {
                          // downloadCertificate(trans, "licence");
                          setCurrentTransaction(trans);
                          showDialog(true);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="simple-border-2">
        <a ref={linkRef} download href={downloadHref}  download={downloadFileName}></a>
      <div className="simple-border" style={{ margin: "10px" }}>
        {reportHeader("Licence Report")}
        <SeachForm />
      </div>
      <TransactionList></TransactionList>
    </div>
  );
};

export default SearchLicences;
