import React, { useState } from 'react';
import './AgencyDirectory.css';
import { reportHeader } from '../../utils/utils';

const AgencyDirectory = () => {
  const [activeCategory, setActiveCategory] = useState('All');

  const agencies = [
    {
      name: "Martime Authority of Jamaica",
      address: "AMC Complex: 188 Spanish Town Road, Kingston 11, PO Box 144, Jamaica, West Indies",
      category: "GENERAL"
    },
    {
      name: "Coast Guard Jamaica",
      address: "JAMPRO Building, 18 Trafalgar Road, Kingston 10, Jamaica, West Indies",
      category: "GENERAL"
    },
    {
      name: "Compliance Monitoring Unit",
      address: "2nd Floor Office Center Building: 10 Ocean Boulevard, Kingston, Jamaica, West Indies",
      category: "GENERAL"
    },
  ];

  const categories = [
    "All",
    "Start a Business",
    "Access Incentives",
    "Acquire Land",
    "Obtain Construction Permits",
    "Secure Approvals",
    "Close a Business",
    "General"
  ];

  return (
    <div className="agency-container">


      {/* <div className="filter-container">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`filter-button ${category === activeCategory ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div> */}

      <div className="cards-container">
        {agencies.map((agency, index) => (
          <div key={index} className="card">
            <div className="card-header-g2g">
              <span className="category-badge">{agency.category}</span>
            </div>
            <h3 className="agency-name">{agency.name}</h3>
            <div>
              <p className="address-label">Address</p>
              <p className="address-text">{agency.address}</p>
            </div>
            <div className="button-container">
              <button className="view-more-button">
                View More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgencyDirectory;